import React from "react"
import styled from "styled-components"
import Button from "../common/Button"

TextAndImage.defaultProps = {
  title: "Thousands of hours of design knowledge.",
  paragraph:
    "We’ve put in thousands of hours of work into this project. We aimed to create the best possible resource for design out there. We really think we’ve done it.",
  imageUrl: "",
  imageRight: true,
  linkTitle: "",
  linkUrl: "",
}

function TextAndImage(props) {
  const { title, paragraph, imageUrl, imageRight, linkTitle, linkUrl } = props

  return (
    <TextAndImageContainer
      className="container align-items-center"
      imageRight={imageRight}
    >
      <TextContainer className="col-5 col-6-t col-12-m">
        <StyledTitle>{title}</StyledTitle>
        <StyledParagraph>{paragraph}</StyledParagraph>
        {linkTitle && linkUrl & <Button />}
      </TextContainer>
      <div className="col-6 col-12-m">
        <StyledImg src={imageUrl} />
      </div>
    </TextAndImageContainer>
  )
}

export default TextAndImage

const TextAndImageContainer = styled.div`
  flex-direction: ${props => (props.imageRight ? "" : "row-reverse")};
`

const TextContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`
const StyledTitle = styled.h2`
  margin-top: 0;
`
const StyledParagraph = styled.p``
const StyledImg = styled.img`
  margin-top: auto;
  align-self: flex-start;
  background-color: ${props => props.theme.colors.lightbackground};
  min-height: 400px;
  width: 100%;
  display: flex;
  border-radius: 8px;
`
