import React from "react"
import styled from "styled-components"
import iconPlaceholder from "../../images/icon-placeholder.png"

IconCard.defaultProps = {
  title: "Thousands of hours of design",
  paragraph: "Learn how to design the core aspects of a website.",
  linkUrl: "#",
  imageUrl: iconPlaceholder,
}

function IconCard(props) {
  const { title, paragraph, linkUrl, imageUrl } = props

  return (
    <IconCardContainer className="col-3 col-6-t col-12-m" href={linkUrl}>
      <StyledImg src={imageUrl} />
      <StyledTitle>{title}</StyledTitle>
      <StyledParagraph>{paragraph}</StyledParagraph>
    </IconCardContainer>
  )
}

export default IconCard

// Styles
const IconCardContainer = styled.a`
  color: ${props => props.theme.colors.primary};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const StyledTitle = styled.h5`
  font-weight: 600;
  margin-top: 0;
  color: ${props => props.theme.colors.white};
`
const StyledParagraph = styled.p``
const StyledImg = styled.img`
  margin-top: auto;
  align-self: flex-start;
  max-height: 48px;
  margin-bottom: 20px;
`
