import React from "react"
import Layout from "../components/template-components/layout"
import HomeHero from "../components/heros/HomeHero"
import BoxedContainer from "../components/common/BoxedContainer"
import TopicCard from "../components/cards/TopicCard"
import TextAndImage from "../components/text-components/TextAndImage"
import IconCard from "../components/cards/IconCard"
import EmailSignup from "../components/email/EmailSignup"

// Icons
import play from "../images/icons/play.svg"
import evolve from "../images/icons/evolve.svg"
import community from "../images/icons/community.svg"
import crafted from "../images/icons/crafted.svg"
import fundamentals from "../images/icons/fundamentals.svg"

const IndexPage = () => (
  <Layout>
    <HomeHero />
    <BoxedContainer>
      <TopicCard
        title="Design fundamentals"
        paragraph="Learn how to design the core aspects of a website."
        imageUrl={fundamentals}
      />
      <TopicCard
        title="Atomic design"
        paragraph="Learn how to create every part of common design patterns."
      />
      <TopicCard
        title="UX design"
        paragraph="Learn how to design with the user in mind."
      />
    </BoxedContainer>
    <BoxedContainer>
      <TextAndImage
        title="The best web design guide there is."
        paragraph="This is a design resource that I would have killed for when learning design. Easily learn the fundamentals of design with hand-written guides, tutorial videos, diagrams, examples and more."
      />
    </BoxedContainer>
    <BoxedContainer className="align-start">
      <IconCard
        title="Text and video guides."
        paragraph="Text and video guides are available for different learning styles."
        linkUrl="#"
        imageUrl={play}
      />
      <IconCard
        title="Constantly evolving."
        paragraph="Unlike phyical books, the idea is to grow with the design industry."
        linkUrl="#"
        imageUrl={evolve}
      />
      <IconCard
        title="Community driven."
        paragraph="Content will grow with what the community wants most. You are the voice."
        linkUrl="#"
        imageUrl={community}
      />
      <IconCard
        title="Carefully crafted."
        paragraph="All guides are created with care and attention. We want the best content possible."
        linkUrl="#"
        imageUrl={crafted}
      />
    </BoxedContainer>
    <BoxedContainer>
      <TextAndImage imageRight={false} />
    </BoxedContainer>
    <BoxedContainer>
      <EmailSignup />
    </BoxedContainer>
  </Layout>
)

export default IndexPage
